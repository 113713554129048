/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: 'customFont';
  src: url('../public/assets/font/Onest-VariableFont_wght.ttf')
}



.App {
  text-align: center;
  max-width: 480px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-header {
  display: flex !important;
  justify-content: center;
  /* box-shadow: 0px 6px 7px 0px rgba(174, 174, 192, 0.40); */
  padding: 20px !important;
  text-align: center;
  font-size: 17px !important;
  font-family: 'customFont', Roboto, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%;
  letter-spacing: 0.193px;
  background: url('../public/assets/images/background.png');
}


.react-chatbot-kit-chat-bot-message-container {
  position: relative;
}

.react-chatbot-kit-chat-bot-message { 
  font-size: 21px;
  font-family: 'customFont', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  max-width: 100%;
  width: fit-content;
  margin-left: 0;
  background: transparent;
  color: black;
  padding: 10px 0;
}

.react-chatbot-kit-user-chat-message {
  font-size: 21px;
  font-family: 'customFont', Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 7px 20px !important;
  display: flex;
  align-items: center;justify-content: center;
  background-color: transparent;
  color: #B7A7F2;
  width: fit-content;
  max-width: 100% ;
  text-align: right !important;
}

button.MuiButtonBase-root {
  font-size: 20px; 
  margin: 12px auto;
  /* min-width: 100px; */
  border: 0 !important;
  background-color : #A68EFF !important;
  color: white;
}

button.MuiButtonBase-root:hover {
 /* box-shadow: inset 6px 6px 7px 0 rgba(174, 174, 192, 0.40), inset -6px -6px 7px 0 #fff !important;*/
}

.react-chatbot-kit-user-avatar, .react-chatbot-kit-user-chat-message-arrow {
  display: none;
}

.react-chatbot-kit-chat-bot-message span {
  color: #0000;
  background: linear-gradient(-90deg, #ffffff 5px, #0000 0) 10px 0, linear-gradient(#070808 0 0) 0 0;
  background-size: calc(2000* 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b .1s infinite steps(1000,end), t calc(1000* .1s) steps(1000) forwards;
  white-space: pre-line;
}

.MuiAvatar-root.MuiAvatar-circular {
  position: absolute;
  width: 50px !important;
  height: 50px !important;
  z-index: 9;
  top: 0;
  bottom: 0;
  margin: auto;
  /*box-shadow: 6px 6px 7px 0 rgb(174 174 192 / 78%), -6px -6px 7px 0 #FFF;*/
}

.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
}

.react-chatbot-kit-chat-input-container {
  position: fixed !important;
  bottom: 25px !important;
  max-width: 480px;
  margin: auto;
}

.react-chatbot-kit-chat-input-form {
  margin: auto;
  width: 95% !important;
  /*box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;*/
  /*flex-direction: row-reverse;*/
}

.react-chatbot-kit-chat-input-form input {
  border: 0 !important;
  /*box-shadow: inset 6px 6px 7px 0 rgba(174, 174, 192, 0.40), inset -6px -6px 7px 0 #fff;*/
  border-radius: 14px;
  padding: 12px 20px;
  font-size: 12px;
  background: linear-gradient(to right, #f5ecff6e, #e1c5ff4f);
  font-weight: 100;
  color: #262626;
}

.react-chatbot-kit-chat-input::placeholder {
  letter-spacing: 2px;
  font-size: 16px;
  color: rgba(38, 38, 38, 0.65) !important;
}

.react-chatbot-kit-chat-input {
  font-size: 16px !important;
}

.react-chatbot-kit-chat-input:focus-visible {
  outline: none;
}

button {
  border-radius: 12px !important;
  /*box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF !important;*/
  max-width: 215px;
  text-align: center;
  font-size: 12px !important;
  font-family: Raleway, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-transform: initial !important;
  color: white;
}

select#selectBox {
  font-size: 21px;
  margin: 25px auto;
  padding: 8px 16px;
  border: 0 !important;
  background-color: #8b9cd2 !important;
  color: white;
  border-radius: 10px;
}

.react-chatbot-kit-chat-btn-send {
  /*display: none;*/
  box-shadow: 0 0 !important;
  width: 50px !important;
  /*margin-right: 10px;
  margin-left: 5px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 8px;
}

.react-chatbot-kit-chat-btn-send svg {
  fill: white ;
  width: 18px;
  filter: drop-shadow(2px 4px 6px black);
}


.react-chatbot-kit-chat-container {
  width: 100% !important;
}

.react-chatbot-kit-chat-message-container{
  height: auto;
  overflow: auto;
  max-height: calc(100vh - 260px);
}

.react-chatbot-kit-chat-inner-container{
  background-color: transparent;
  /*background: url('../public/assets/images/background.png') center;*/
  /*height: 100vh !important;*/
}



#ai-con {
  position: fixed;
  top: 39px;
  left: 0;
  width: 180px;
  display: none;
}
#ai-con #ai-video {
  width: 100%;
  z-index: 1;
}
#ai-con .setting {
  cursor: pointer;
  width: 24px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
#ai-conf {
  display: none;
  width: 180px;
  height: 150px;
}

.chatId {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: #a3a3a3;
  font-size: 10px;
}
