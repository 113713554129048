.txt {
    font-size: 31px;
    color: white;
    line-height: 42px;
    top: 30px;
    transition: opacity 1.2s linear, line-height .3s linear;
}

.coiffeurs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    margin: 25px auto;
    overflow: auto;
    transition: opacity 1.8s linear;
}

.coiffeur {
    min-width: 70px;
    min-height: 70px;
    background: white;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    box-shadow: 0 0 5px 2px #00000008;
}

.coiffeur > span {
    width: fit-content;
    max-width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
}

.chois-btns {
    margin: 35px auto;
    width: fit-content;
    transition: opacity 1.8s linear;
    a {
        color: white;
    }
}

.chois-btns > button {
    width: 120px;
    padding: 8px;
    border: 1px #fff solid;
    background: #ffffff24;
    font-size: 17px !important;
    border-radius: 40px !important;
    margin: 0 12px;
}

@keyframes fadeIn {
    0% { opacity: 0.2; }
    100% { opacity: 1; }
  }
